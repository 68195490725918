<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-bot-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">دسترسی های ربات</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <p class="text-lg font-bold" v-if="res !== false && res.ok == false">
        ربات نتوانست اطلاعات را دریافت کند
      </p>
      <div v-else>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.status == 'administrator' ? okClass : failClass"
        >
          <div class="py-2 font-bold" v-if="res.result.status == 'administrator'">
            <h2>ربات در گروه شما مدیر است</h2>
          </div>
          <div class="py-2 font-bold" v-else><h2>ربات در گروه شما مدیر نیست</h2></div>
        </div>
        <div
          v-if="res.result.is_anonymous"
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="failClass"
        >
          <div class="py-2 font-bold">
            <h2>ربات ادمین ناشناست است</h2>
            <hr class="my-3 border-blueGray-200" />
            <p>
              ربات ادمین ناشناس است. ناشناس بودن ربات ممکن است باعث اختلال جدی در کارکرد
              ربات شود
            </p>
          </div>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_change_info ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی ویرایش اطلاعات</h2></div>
          <h3 class="mt-1 font-bold">can change info</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_change_info ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            اگر ربات این درسترسی را داشته باشد میتواند اطلاعات گروه مانند اسم گروه و بیو
            گروه را تغییر دهد. البته ربات بدون اجازه شما چیزی را تغییر نمیدهد. بعضی از
            امکانات پنل به فعال بودن این دسترسی نیاز دارند.
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_delete_messages ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی حذف پیام ها</h2></div>
          <h3 class="mt-1 font-bold">can delete messages</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_delete_messages ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            ربات حتما باید این دسترسی را داشته باشد در صورتی که این دسترسی را نداشته باشد
            نمی‌تواند پیام هارا پاک کند و عملا قفل گروه، حذف لینک و ... کار نخواهد کرد
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_invite_users ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی دعوت افراد</h2></div>
          <h3 class="mt-1 font-bold">can invite users</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_invite_users ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            اگر ربات این دسترسی را نداشته باشد نمی‌تواند برای گروه لینک بسازد. و همچنین
            نمیتواند این دسترسی را به فرد دیگری بدهد. بهتر است به ربات این دسترسی را بدهید
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_manage_chat ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی مدیریت چت</h2></div>
          <h3 class="mt-1 font-bold">can manage chat</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_manage_chat ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            با فعال بودن این دسترسی ربات میتواند ممبر های گروه و همچنین ادمین های ناشناس
            را ببیند.همچنین اگر حالت آهسته گروه فعال است ربات باز هم میتواند پیام دهد این
            دسترسی برای مدیریت گروه بسیار مهم است و غیر فعال کردن آن باعث اختلال در عملکرد
            ربات می‌شود.
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_manage_voice_chats ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی مدیریت ویس چت ها</h2></div>
          <h3 class="mt-1 font-bold">can manage voice chats</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_manage_voice_chats ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            این دسترسی برای مدیریت ویسچت های گروه است. در حال حاظر ربات نیازی به این
            دسترسی ندارد اما بهتر است فعال باشد
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_pin_messages ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی پین کردن پیام ها</h2></div>
          <h3 class="mt-1 font-bold">can pin messages</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_pin_messages ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            اگر این دسترسی فعال باشد ربات می‌تواند پیام ها را پین کند. در حال حاظر ربات به
            این دسترسی نیازی ندارد اما در آینده امکاناتی اضافه می‌شود که به این دسترسی
            نیاز دارد. پس بهتر است فعال باشد.
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_promote_members ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی ارتقاع کاربران</h2></div>
          <h3 class="mt-1 font-bold">can promote members</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_promote_members ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            اگر این دسترسی فعال باشد ربات میتواند کاربران را مدیر کند. دادن این دسترسی
            دلخواه است اما بعضی از قابلیت های ربات به آن وابسته است. همچنین با فعال بودن
            این گزینه اگر به طور ناخواسته اکانت شما حذف شد ربات میتواند اکانت جدید شما را
            مدیر کند
          </p>
        </div>
        <div
          class="bg-blueGray-100 border border-t-4 p-3 rounded-md mb-3"
          :class="res.result.can_restrict_members ? okClass : failClass"
        >
          <div class="py-2 font-bold"><h2>دسترسی محدود کردن کاربران</h2></div>
          <h3 class="mt-1 font-bold">can restrict members</h3>
          <hr class="my-3 border-blueGray-200" />
          <div>
            وضعیت:
            <span class="font-bold text-lg">
              {{ res.result.can_restrict_members ? "دارد" : "ندارد" }}
            </span>
          </div>
          <p>
            این دسترسی هم مانند دسترسی حذف پیام بسار مهم است و اگر فعال نباشید ربات
            نمیتواند کاربران مخرب را محدود و حذف کند
          </p>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
  components: {
    BodySimple,
    Icon,
  },
  data() {
    return {
      firstLoading: true,
      okClass: ["border-green-400"],
      failClass: ["border-red-400"],
      group: {},
      res: false,
    };
  },
  mounted() {
    this.getData();
    this.getAccess();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    getAccess() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group-bot-access/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.res = response.data.res;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
